<template>
  <div class="suggest" :style="{'height': clienHeight}">
    <h4>
      <i @click="close" class="jym-close"></i>反馈内容
    </h4>
    <div class="main">
      <textarea
        cols="30"
        rows="10"
        v-model="content"
        maxlength="200"
        placeholder="请简单描述敏的问题，我们尽快给您回复"
      ></textarea>
      <div>
        <p>上传图片(选填)</p>
        <vue-core-image-upload
          class="btn btn-primary"
          :crop="false"
          compress="80"
          :headers="headers"
          @imageuploaded="imageuploaded"
          :max-file-size="5242880"
          :multiple="true"
          :multiple-size="9"
          :credentials="false"
          inputAccept="image/*"
          inputOfFile="file"
          :url="url"
        >
          <div class="upload">
            <i class="jym-tuwen1"></i>
          </div>
        </vue-core-image-upload>
      </div>

      <div class="img-box">
        <div class="img" v-for="(item, index) in imagesData" :key="index">
          <img @click="ImagePreview(imagesData, index)" v-lazy="item" />
          <i class="jym-close" @click="del(index)"></i>
        </div>
      </div>

      <!-- <p>请输入您的手机号，以便我们尽快为您服务。</p>
      <mt-field label="手机号" placeholder="请输入手机号" type="tel" v-model="mobile"></mt-field>-->
      <button class="btn-bg" @click="submit">提交</button>
    </div>

    <van-image-preview
      v-if="imageShow"
      v-model="imageShow"
      :images="images"
      :showIndex="false"
      :showIndicators="true"
      :startPosition="startPosition"
      @close="vanClose"
    ></van-image-preview>
  </div>
</template>

<script>
import { MessageBox } from "mint-ui";
import { VUE_APP_API_URL } from "@utils";
import VueCoreImageUpload from "vue-core-image-upload";
export default {
  props: ["id"],
  components: {
    VueCoreImageUpload,
  },
  data() {
    return {
      url: `${VUE_APP_API_URL}/upload/image`,
      headers: {
        "Authori-zation": "Bearer " + this.$store.state.app.token,
      },
      imagesData: [],
      clienHeight: "",
      content: "",
      mobile: "",
      refund_reason_wap_img: [],
      imageShow: false,
      images: [],
      startPosition: 0
    };
  },
  created() {
    this.clienHeight = document.body.clientHeight / 50 - 3 + "rem";
  },
  methods: {
    // 关闭图片预览
    vanClose() {
      this.images = [];
      this.imageShow = false;
    },

    // 图片预览
    ImagePreview(images, index) {
      this.images = images;
      this.imageShow = true;
      this.startPosition = index;
    },

    // 图片上传
    imageuploaded(res) {
      let that = this;

      if (res.status == 200) {
        that.imagesData.push(res.data.url);
      } else {
        that.$dialog.error(res.msg);
      }
    },

    // 图片删除
    del(index) {
      let that = this;
      that.imagesData.splice(index, 1);
    },

    close() {
      this.$emit("closeDialog");
    },

    submit() {
      if (!this.content) {
        MessageBox("请填写反馈内容！");
        return;
      } else {
        let params = {
          content: this.content,
          pid: this.id,
          img: this.imagesData,
          mobile: this.mobile || "",
        };
        this.$emit("submit", params);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.suggest {
  width: 100%;
  height: auto;
  background: white;
  overflow: scroll;
  h4 {
    height: auto;
    width: 100%;
    font-size: 0.28rem;
    text-align: center;
    font-weight: 500;
    padding: 0.3rem;
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    i {
      float: left;
      font-size: 0.42rem;
    }
  }
  .main {
    margin-top: 0.8rem;
    padding: 0.2rem 0.3rem;
    .img-box {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      margin: 0.3rem -0.1rem 0;
      .img {
        width: 2rem;
        height: 2rem;
        margin: 0.1rem;
        position: relative;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
        i {
          position: absolute;
          top: 0;
          right: 0;
          background: red;
          color: white;
          width: 0.4rem;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.42rem;
          text-align: center;
          z-index: 999;
        }
      }
    }
    .upload {
      background: white;
      width: 80px;
      height: 80px;
      background-color: #f7f8fa;
      border-radius: 8px;
      text-align: center;
      i {
        color: #dcdee0;
        font-size: 24px;
        line-height: 80px;
      }
      .btn-primary {
        background-color: #16c2c2;
        color: #fff;
        padding: 0.3rem;
        height: 4rem;
        width: 5rem;
        float: left;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    textarea {
      width: 100%;
      background: #f7f8fa;
      border-radius: 0.2rem;
      padding: 0.2rem;
      font-size: 0.28rem;
    }
    p {
      margin: 0.3rem 0 0.1rem;
      font-size: 0.28rem;
      color: #333;
    }
    button {
      font-size: 0.32rem;
      color: white;
      width: 7.1rem;
      height: 0.9rem;
      border-radius: 0.1rem;
      text-align: center;
      line-height: 0.9rem;
      margin-top: 0.5rem;
    }
    .mint-cell-wrapper {
      font-size: 0.26rem;
    }
  }
}
</style>
