<template>
  <div class="suggest" ref="container">
    <div v-if="suggestList.length > 0">
      <ul class="lesson-list">
        <li v-for="(item, i) in suggestList" :key="i">
          <div class="tips" @click="suggestDetail(item.id, i)">
            <span>{{ item.content }}</span>
            <time>{{ item.create_time }}</time>
          </div>
        </li>
      </ul>
    </div>
    <div v-else class="emptyData" v-show="initLoading">
      <img src="@assets/images/emptyData.png" />
      <p>暂无反馈</p>
    </div>

    <button class="suggest-btn btn-bg" @click="suggest(null)">意见反馈</button>

    <!-- 反馈内容 -->
    <mt-popup v-model="suggestVisible" position="bottom">
      <suggest-dialog ref="suggest" :id="id" @closeDialog="closeDialog" @submit="submit"></suggest-dialog>
    </mt-popup>

    <!-- 反馈详情 -->
    <mt-popup v-model="detailVisible" position="bottom">
      <div class="detail">
        <div v-for="(detail, j) in detailList" :key="j">
          <h4>{{ detail.nickname }}:</h4>
          <div class="img-box">
            <div v-for="(src, i) in detail.img" :key="i">
              <img @click="ImagePreview(detail.img, i)" v-lazy="src" />
            </div>
          </div>
          <p>{{ detail.content }}</p>
          <time>{{ detail.create_time }}</time>
        </div>
        <button @click="suggest(detailList[0].id || '')" class="btn-bg small">追加反馈</button>
      </div>
    </mt-popup>

    <van-image-preview
      v-if="imageShow"
      v-model="imageShow"
      :images="images"
      :showIndex="false"
      :showIndicators="true"
      :startPosition="startPosition"
      @close="vanClose"
    ></van-image-preview>
  </div>
</template>

<script>
import { feedbackList, feedbackDetail, feedbackSubmit } from "@api/user";
import suggestDialog from "@views/user/suggestDialog";

export default {
  name: "suggest",
  components: { suggestDialog },
  data() {
    return {
      id: "",
      page: 1,
      limit: 10,
      suggestList: [],
      detailList: "",
      suggestVisible: false,
      loadend: false,
      loading: false,
      detailVisible: false,
      imageShow: false,
      images: [],
      initLoading: false,
      startPosition: 0
    };
  },
  created() {
    this.loadMore();
    this.$scroll(this.$refs.container, () => {
      this;
      !this.loading && this.loadMore();
    });
  },
  methods: {
    // 关闭图片预览
    vanClose() {
      this.images = [];
      this.imageShow = false;
    },

    // 图片预览
    ImagePreview(images, index) {
      this.images = images;
      this.imageShow = true;
      this.startPosition = index;
    },

    // 回显反馈列表
    loadMore() {
      let that = this;
      if (that.loading) return; //阻止下次请求（false可以进行请求）；
      if (that.loadend) return; //阻止结束当前请求（false可以进行请求）；
      that.loading = true;
      feedbackList({ page: that.page, limit: that.limit }).then((res) => {
        that.loading = false;
        //apply();js将一个数组插入另一个数组;
        that.suggestList.push.apply(that.suggestList, res.data);
        that.loadend = res.data.length < that.limit; //判断所有数据是否加载完成；
        that.page = that.page + 1;
        that.initLoading = true;
      });
    },

    // 获取反馈详情
    suggestDetail(id) {
      feedbackDetail(id).then((res) => {
        this.$set(this, "detailList", res.data);
        this.detailVisible = true;
      });
    },

    // 意见反馈弹窗
    suggest(id) {
      this.id = id;
      this.detailVisible = false;
      this.suggestVisible = true;
    },

    // 关闭弹窗
    closeDialog() {
      this.suggestVisible = false;
    },

    // 反馈内容提交
    submit(params) {
      feedbackSubmit(params)
        .then(() => {
          this.suggestVisible = false;
          this.$dialog.success("反馈成功");
          this.$router.go(0);
        })
        .catch((err) => {
          this.$dialog.err(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.suggest {
  ul {
    overflow: scroll;
    li {
      padding: 0 0.3rem;
      border-bottom: 0.02rem dashed #ddd;
      overflow: hidden;
      .tips {
        display: flex;
        justify-content: space-between;
        font: 0.28rem/1rem "microsoft yahei";
        span {
          width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        time {
          color: #a9a9a9;
          font-size: 0.26rem;
        }
      }
    }
  }
  .suggest-btn {
    font-size: 0.32rem;
    color: #fff;
    width: 6.9rem;
    height: 0.9rem;
    border-radius: 0.1rem;
    text-align: center;
    line-height: 0.9rem;
    margin: 0.5rem 0.3rem 0;
    position: fixed;
    bottom: 0.5rem;
  }
  .mint-popup {
    width: 100%;
    .detail {
      width: 100%;
      height: auto;
      overflow: hidden;
      font: 0.28rem/0.4rem "microsoft yahei";
      background: #fafafa;
      padding: 0.2rem;
      border-radius: 0.2rem;
      margin-bottom: 0.3rem;
      > div {
        margin: 0.2rem;
        h4 {
          font-weight: 500;
          font-size: 0.26rem;
          color: #26a2ff;
        }
        p {
          font-size: 0.26rem;
          margin-top: 0.06rem;
        }
        time {
          color: #a9a9a9;
          font-size: 0.24rem;
        }
        .img-box {
          display: flex;
          margin: 0 -0.1rem;
          img {
            width: 2rem;
            height: 2rem;
            margin: 0.1rem;
          }
        }
      }
    }
    .small {
      color: white;
      font-size: 0.26rem;
      margin: 0.2rem 0 0 0.2rem;
      padding: 0.1em 0.2rem;
      border-radius: 0.2rem;
    }
  }
}
</style>